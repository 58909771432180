import React, { useState } from "react";
import { BreakdownOfReservationProps } from "../../utils/interfaces/interfaces";
import {
  subTitle,
  text,
  title,
  titleMount,
  titleMountM,
  titleR,
} from "../../css/style";
import { systemLanguage } from "../../utils/constants/constants";

const BreakdownOfReservation: React.FC<BreakdownOfReservationProps> = ({
  language,
  dataDesgloseComision,
  bookingCode,
}) => {
  const [mostrarBotonArriba, setMostrarBotonArriba] = useState(true);
  const firstItem = dataDesgloseComision[0];
  const t = systemLanguage[language as "ES" | "EN"] || systemLanguage.ES;
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);
  };
  const formatPercentage = (value: number) => {
    return `${value}%`;
  };
  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="container mt-4">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="btn"
          type="button"
          onClick={reload}
          style={{
            backgroundColor: "#23246c",
            color: "white",
            marginBottom: "1rem",
          }}
        >
          Buscar otro localizador
        </button>
      </div>
      <div className="row">
        <div className="col">
          <div className="alert alert-success text-center" role="alert">
            <h5 className="alert-heading">
              {t.localizador}: {bookingCode}
            </h5>
            <p>{t.reservaConfirmada}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header">
              <p style={title}>{t.productosReservados}</p>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center">
                <i
                  className="fas fa-box-open"
                  style={{
                    fontSize: "24px",
                    color: "#e53935",
                    marginLeft: "1.2rem",
                  }}
                ></i>
                <p style={title}>{t.paquete}</p>
              </div>
              <p style={subTitle}>{firstItem?.nombrePaquete}</p>
              <p style={text}>{firstItem?.fechas}</p>
              <p style={text}>{firstItem?.pasajeros}</p>
              {firstItem?.listadoPasajeros &&
                firstItem.listadoPasajeros.length > 0 && (
                  <div>
                    <p style={subTitle}>{t.detallePasajeros}</p>
                    <ul>
                      {firstItem.listadoPasajeros.map((pasajero, index) => (
                        <li key={index} style={text}>
                          {pasajero}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <div className="d-flex justify-content-between align-items-center">
                <p style={text}>{t.precioTotalReserva}</p>
                <p style={titleMount}>
                  {formatCurrency(firstItem?.precioTotal)} {firstItem?.moneda}
                </p>
              </div>
              <div className="row">
                <div className="col">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "#e53935",
                        color: "#fff",
                        width: "auto",
                        height: "auto",
                        marginLeft: "1.2rem",
                        padding: ".3rem .15rem",
                        marginRight: "-.5rem",
                      }}
                    >
                      <i
                        className="fa-solid fa-dollar-sign"
                        style={{
                          fontSize: "18px",
                        }}
                      ></i>
                    </div>
                    <p style={title}>{t.precioTotalReserva}</p>
                  </div>
                  <div className="card mb-3 mx-3">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.precio}</p>
                        <p style={titleMount}>
                          {formatCurrency(firstItem?.precio)}{" "}
                          {firstItem?.moneda}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.noComisionable}</p>
                        <p style={titleMountM}>
                          {formatCurrency(firstItem?.noComisionable)}{" "}
                          {firstItem?.moneda}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.iva}</p>
                        <p style={titleMountM}>
                          {formatCurrency(firstItem?.ivaPrecio)}{" "}
                          {firstItem?.moneda}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.otrosImpuestos}</p>
                        <p style={titleMountM}>
                          {formatCurrency(firstItem?.otrosImpuestos)}{" "}
                          {firstItem?.moneda}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.costo}</p>
                        <p style={titleMountM}>
                          {formatCurrency(firstItem?.costo)} {firstItem?.moneda}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.ivaCosto}</p>
                        <p style={titleMountM}>
                          {formatCurrency(firstItem?.ivaCosto)}{" "}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.comision}</p>
                        <p style={titleMountM}>
                          {formatCurrency(firstItem?.comision)}{" "}
                          {firstItem?.moneda}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p style={text}>{t.ivaComision}</p>
                        <p style={titleMountM}>
                          {formatCurrency(firstItem?.ivaComision)}{" "}
                          {firstItem?.moneda}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header">
              <p style={titleR}>{t.titularReserva}</p>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-5" style={{ margin: "1rem" }}>
                  <p>
                    <strong>{t.nombre}:</strong> {firstItem.bhNombre}
                  </p>
                  <p>
                    <strong>{t.codigoPostal}:</strong> {firstItem.bhCP}
                  </p>
                  <p>
                    <strong>{t.pais}:</strong> {firstItem.bhPais}
                  </p>
                  <p>
                    <strong>{t.correoElectronico}:</strong> {firstItem.bhEmail}
                  </p>
                </div>
                <div className="col-md-5" style={{ margin: "1rem" }}>
                  <p>
                    <strong>{t.direccion}:</strong> {firstItem.bhDireccion}
                  </p>
                  <p>
                    <strong>{t.ciudad}:</strong> {firstItem.bhCiudad}
                  </p>
                  <p>
                    <strong>{t.telefono}:</strong> {firstItem.bhTelefono}
                  </p>
                  <p>
                    <strong>{t.nacionalidad}:</strong>{" "}
                    {firstItem.bhNacionalidad}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header">
              <p style={titleR}>{t.informacionAgente}</p>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-5" style={{ margin: "1rem" }}>
                  <p>
                    <strong>{t.agencia}:</strong> {firstItem.adAgencia}
                  </p>
                  <p>
                    <strong>{t.tramitadoPor}:</strong>{" "}
                    {firstItem.adReservadoPor}
                  </p>
                </div>
                <div className="col-md-5" style={{ margin: "1rem" }}>
                  <p>
                    <strong>{t.correoElectronico}:</strong> {firstItem.adEmail}
                  </p>
                  <p>
                    <strong>{t.referenciaAgencia}:</strong>{" "}
                    {firstItem.adReferenciaAgencia}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="btn"
          type="button"
          onClick={reload}
          style={{
            backgroundColor: "#23246c",
            color: "white",
            marginBottom: "1rem",
          }}
        >
          Buscar otro localizador
        </button>
      </div>
    </div>
  );
};

export default BreakdownOfReservation;
