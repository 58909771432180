import React, { useState } from "react";
import { WithoutLocatorProps } from "../../utils/interfaces/interfaces";
import { loaderContainerStyle } from "../../css/style";
import Header from "../Header";

const WithoutLocator: React.FC<WithoutLocatorProps> = ({
  onBookingCodeSubmit,
}) => {
  const [bookingCode, setBookingCode] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^[a-zA-Z0-9]*$/.test(value) && value.length <= 6) {
      setBookingCode(value);
    }
  };

  const handleButtonClick = () => {
    if (bookingCode.length === 5) {
      setLoader(true);
      onBookingCodeSubmit(bookingCode);
    } else if (bookingCode.length === 6) {
      setLoader(true);
      onBookingCodeSubmit(bookingCode);
    }
  };

  return (
    <React.Fragment>
      <div className="container mt-4">
        {!loader && (
          <>
            <div className="container">
              <div className="row">
                <p className="fs-4 text-center">Ingresa tu localizador</p>
                <div className="col"></div>
                <div className="col">
                  <input
                    type="text"
                    maxLength={6}
                    value={bookingCode}
                    className="form-control"
                    onChange={handleInputChange}
                    placeholder="Localizador"
                    aria-label="Booking code input"
                    aria-describedby="inputGroupPrepend"
                    style={{
                      borderColor: "red", // Contorno rojo
                    }}
                  />
                </div>
                <div className="col">
                  {" "}
                  <button
                    className="btn"
                    type="button"
                    onClick={handleButtonClick}
                    disabled={bookingCode.length <= 4}
                    style={{
                      backgroundColor: "#23246c", // Color del botón
                      color: "white",
                    }}
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {loader && (
          <div style={loaderContainerStyle}>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default WithoutLocator;
