import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { route } from "./utils/route-config";
import Home from "./views/Home";

function App() {
  return (
    <Router>
      <Routes>
        {route.map((rout) => (
          <Route
            key={rout.path}
            path={rout.path}
            element={<Home content={rout.element} />}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
