export const titleR = {
    margin: "1rem",
    fontSize: "27px",
    fontWeight: "700",
    color: "#323232",
  };

  export const title = {
    margin: "1rem",
    fontSize: "23px",
    fontWeight: "700",
    color: "#323232",
  };

  export const titleMount = {
    margin: "1rem",
    fontSize: "23px",
    fontWeight: "700",
    color: "#323232",
  };

  export const titleMountM = {
    margin: "1rem",
    fontSize: "18px",
    fontWeight: "700",
    color: "#656565",
  };

  export const subTitle = {
    margin: "1rem",
    fontSize: "18px",
    fontWeight: "700",
    color: "#323232",
  };

  export const text = {
    margin: "1rem 1.5rem",
    fontSize: "18px",
    color: "#323232",
  };

  export  const loaderContainerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };