import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DesgloseComisionData } from "../utils/interfaces/interfaces";
import BreakdownOfReservation from "../components/breakdownOfReservation/BreakdownOfReservation";
import { loaderContainerStyle } from "../css/style";
import WithoutLanguage from "../components/breakdownOfReservation/WithoutLanguage";
import WithoutLocator from "../components/breakdownOfReservation/WithoutLocator";
import { fetchGetDesgloseComision } from "../utils/functions/Get";
import IncorrectLocator from "../components/breakdownOfReservation/IncorrectLocator";
import Swal from "sweetalert2";

const Breakdown: React.FC = () => {
  const { language: languageParam, bookingCode } = useParams<{
    language: string;
    bookingCode?: string;
  }>();

  const [language, setLanguage] = useState<string>(languageParam || "ES");
  const [booking, setBooking] = useState<string>(bookingCode || "");
  const [mostrarDesgloce, setMostrarDesgloce] = useState<boolean>(false);
  const [dataDesgloseComision, setDataDesgloseComision] = useState<
    DesgloseComisionData[]
  >([]);
  const [systemLanguage, setSystemLanguage] = useState<string>("");

  const bookingCodeFetchGetDesgloseComision = async (
    newBookingCode?: string
  ) => {
    let bk = newBookingCode || bookingCode || booking;
    if (bk) {
      searchBooking(bk);
    }
  };

  const searchBooking = async (bk: string) => {
    try {
      const data = await fetchGetDesgloseComision(bk);
      const isInvalidData =
        data.length === 1 &&
        data[0].nombrePaquete === null &&
        data[0].fechas === null &&
        data[0].pasajeros === null &&
        data[0].precioTotal === 0 &&
        data[0].moneda === null;
      if (isInvalidData) {
        Swal.fire({
          title: "Error",
          text: "El localizador no existe.",
          icon: "error",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        setMostrarDesgloce(false);
        return;
      }
      setDataDesgloseComision(data);
      setMostrarDesgloce(true);
    } catch (error) {
      console.error("Error al obtener el desglose de la comisión:", error);
    }
  };

  useEffect(() => {
    if (language === "ES") {
      setSystemLanguage("ES");
      bookingCodeFetchGetDesgloseComision();
    } else if (language === "EN") {
      setSystemLanguage("EN");
      bookingCodeFetchGetDesgloseComision();
    }
  }, [language, bookingCode]);

  const handleBookingCodeSubmit = (newBookingCode: string) => {
    setBooking(newBookingCode);
    bookingCodeFetchGetDesgloseComision(newBookingCode);
  };

  if (!language) {
    return <WithoutLanguage />;
  }

  if (!booking && !bookingCode) {
    return <WithoutLocator onBookingCodeSubmit={handleBookingCodeSubmit} />;
  }

  return (
    <React.Fragment>
      {!mostrarDesgloce && (
        <div style={loaderContainerStyle}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {mostrarDesgloce && (
        <BreakdownOfReservation
          language={systemLanguage}
          dataDesgloseComision={dataDesgloseComision}
          bookingCode={bookingCode || booking}
        />
      )}
    </React.Fragment>
  );
};

export default Breakdown;
