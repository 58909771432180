import React from "react";
import Breakdown from "./Breakdown";
import Header from "../components/Header";

interface HomeProps {
  content: React.ReactNode;
}

const Home: React.FC<HomeProps> = ({ content }) => {
  return (
    <div>
      <Header />
      <Breakdown />
    </div>
  );
};

export default Home;
