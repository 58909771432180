import React from "react";
const withoutLanguage: React.FC = () => {
  return (
    <div className="container mt-4">
      {" "}
      <div className="alert alert-danger" role="alert">
        <center>Sin Lenguaje - Without language</center>
      </div>
    </div>
  );
};

export default withoutLanguage;
