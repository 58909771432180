import React from "react";

function Header() {
  return (
    <header className="p-3 text-white" style={{ background: "#23246c" }}>
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a>
            <img
              style={{ height: "auto", width: "40%" }}
              src="https://eurocdn.azureedge.net/bucket/EBE/euor/967EUROMUNDO-BLANCO-webp.webp"
              alt="logo"
            />
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
