// src/utils/get.ts
import axios from "axios";
import { GetDesgloseComision } from "../endPonits";
import { DesgloseComisionData } from "../interfaces/interfaces";


export const fetchGetDesgloseComision = async (
  bookingCode: string | undefined
): Promise<DesgloseComisionData[]> => {
  try {
    const response = await axios.get(GetDesgloseComision + bookingCode);
    const data = response.data;
    return [data];
  } catch (error) {
    console.error("Error al obtener el desglose de la comisión:", error);
    throw error;
  }
};
