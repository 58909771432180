type Language = 'ES' | 'EN';
export const systemLanguage: Record<Language, { [key: string]: string }> = {
    ES: {
      localizador: "Localizador",
      reservaConfirmada: "Su reserva ha sido confirmada correctamente. Recibirá el bono en su email.",
      productosReservados: "Productos reservados",
      paquete: "Paquete",
      fechas: "Fechas",
      pasajeros: "Pasajeros",
      precioTotalReserva: "Precio total reserva",
      precio: "Precio",
      noComisionable: "No comisionable",
      iva: "IVA",
      otrosImpuestos: "Otros Impuestos",
      costo: "Costo",
      ivaCosto: "IVA costo",
      comision: "Comisión",
      ivaComision: "IVA Comisión",
      titularReserva: "Titular de la reserva",
      nombre: "Nombre",
      codigoPostal: "Código postal",
      pais: "País",
      correoElectronico: "Correo electrónico",
      direccion: "Dirección",
      ciudad: "Ciudad",
      telefono: "Teléfono",
      nacionalidad: "Nacionalidad",
      informacionAgente: "Información del agente",
      agencia: "Agencia",
      tramitadoPor: "Tramitado por",
      referenciaAgencia: "Referencia agencia",
    },
    EN: {
      localizador: "Booking code",
      reservaConfirmada: "Your reservation has been successfully confirmed. You will receive the voucher in your email.",
      productosReservados: "Booked products",
      paquete: "Package",
      fechas: "Dates",
      pasajeros: "Passengers",
      precioTotalReserva: "Total booking price",
      precio: "Price",
      noComisionable: "Non-commissionable",
      iva: "VAT",
      otrosImpuestos: "Other taxes",
      costo: "Cost",
      ivaCosto: "Cost VAT",
      comision: "Commission",
      ivaComision: "Commission VAT",
      titularReserva: "Booking holder",
      nombre: "Name",
      codigoPostal: "Postal code",
      pais: "Country",
      correoElectronico: "Email",
      direccion: "Address",
      ciudad: "City",
      telefono: "Phone",
      nacionalidad: "Nationality",
      informacionAgente: "Agent information",
      agencia: "Agency",
      tramitadoPor: "Processed by",
      referenciaAgencia: "Agency reference",
    },
  };